
export function modifyStatInfo (id,statInfo,val)  {
    try {
		let nstatInfo="";
		if (id > 31) console.log("modifyStatInfo eventId >31!!!!");
		if (typeof statInfo !== 'string') {
			console.log ("modifyStatInfo no string",typeof statInfo); 
			return false;
		};
		if (statInfo.length === 64) {
			nstatInfo = "0x"+statInfo;
		}
		else {
			if (statInfo.length !== 66) { 
				console.log ("modifyStatInfo Length!!",statInfo.length); 
				return false;
			}
			else {
				nstatInfo=statInfo;
			};
				
		}
		if (!(Boolean(nstatInfo.match(/^0x[0-9a-f]+$/i))&&Boolean(val.match(/[0-9A-Fa-f][0-9A-Fa-f]/)))){
			console.log ("modifyStatInfo match statIinfo ,val ",nstatInfo,",",val); 
			return false;
		}			
		return (id === 0) ? ("0x"+val+nstatInfo.substring(4)) :
							(nstatInfo.substring(0,2+2*id)+val+nstatInfo.substring((2+2*(id+1))));
  } catch (e) {
    console.log("modifyStatInfo try: ",e)
  }
};

export function u8arr2hex(u8a) { //[1,0,?,?,?,?,?,?] ->uint
    try {
	let tath=parseInt(u8a.map(item => Number(item?1:0)).join(''),2).toString(16); // _tatrbool.map ->tatInd.map
	return(tath.length===1)? "0"+tath:tath
	}
	catch  (e) {
    console.log("u8arr2hex: ",e)
  }
};
export function atrbool2statInfo(atrbool) { //[1,0,?,?,?,?,?,?] ->uint
    try {
		let tstatInfo="0x";
		atrbool.map( (atbool,i) => {
			tstatInfo=tstatInfo + u8arr2hex(atbool);
		});
		tstatInfo=(tstatInfo+"0000000000000000000000000000000000000000000000000000000000000000").substring(0,66);
		console.log("atrbool2statInfo: ",tstatInfo);
		return tstatInfo;
	}catch  (e) {
    console.log("u8arr2hex: ",e)
	return "";
  }
};

export function atrboolI2statInfo(atbool,statInfo,i) { 
    try {
		const val = u8arr2hex(atbool); console.log("ab2ba atrboolI2infoStat(",atbool,",\n",statInfo,",\n",i,")=", val);
		return modifyStatInfo (i,statInfo,val); 
		
	}
	catch  (e) {
    console.log("atrboolI2statInfo: ",e);
	return "";
  }
};
export function statInfo2atrbool(statInfo) { 
    try {
		let val =[];
		 for (let i = 0; i < statInfo.length-1; i++) {
			val.push(parseInt(statInfo.substring(2+2*i,2+2*(i+1))).toString(2));
		 }
		 console.log("statInfo2atrbool: ",val);
		return val;
	}
	catch  (e) {
    console.log("statInfo2atrbool: ",e);
	return "";
  }
};