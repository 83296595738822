import React, { useState, useEffect, useMemo, useLayoutEffect } from 'react'
import { ethers } from 'ethers';
import { useEthereumContext } from '../ethereumContext/EthereumContext';
import {Text, Wrap, WrapItem, Box, Image, HStack, VStack, Flex, Spacer, Grid, SimpleGrid,
	Center, Heading,Input,Button,IconButton,useColorMode,useColorModeValue, Link} from '@chakra-ui/react';
import l500 from "../../images/l500.jpg";
import {useBreakpointValue} from '@chakra-ui/react';
import { useMediaQuery } from '@chakra-ui/media-query';//github.com/alexmcdermid/portfolio-website-chakra/blob/main/src/App.js


import { SunIcon, MoonIcon } from '@chakra-ui/icons'; //Navbar
import ConnectWallet from '../connectWallet/ConnectWallet';//Navbar

import {modifyStatInfo,u8arr2hex ,atrbool2statInfo} from '../../utils/ab2ba'; 
import { getUser, setUser, checkAddress, fetchEvents, handleBuyTicket, checkSolution, fetchImg, preSelEvId } from '../../utils/actions';
//import { fetchEvents} from '../../utils/actions';
//import { handleBuyTicket } from '../../utils/actions'
//import { checkSolution, fetchImg } from '../../utils/actions'
//import { preSelEvId } from '../../utils/actions'
//			import PosterCloud from '../../abi/PosterCloud.json'
//			import Tickets from '../../abi/Tickets.json'
//------
import  ProtectedForm  from '../protectedForm/ProtectedForm.jsx' ;
//
//import {ImageGallery } from '../imageGallery/ImageGallery';

import localforage from 'localforage';


export default function CreateMemnotic() {
 const util= require('util');
 const variant = useBreakpointValue(
    {
      base: 'small',
      md: 'medium',
	  lg: 'large',
    },
    {
      // Breakpoint to use when mediaqueries cannot be used, such as in server-side rendering
      // (Defaults to 'base')
      fallback: 'md',
    },
  );	
const [isNotSmallerScreen] = useMediaQuery("(min-width:600px)");//	https://github.com/alexmcdermid/portfolio-website-chakra/blob/main/src/App.js
//https://github.com/varshitha-008/Event_Management/blob/main/frontend/src/components/Navbar.jsx
// <Flex direction={isNotSmallerScreen ? "row" : "column"} spacing="200px" align="flex-start">

  const { colorMode, toggleColorMode } = useColorMode();//Navbar
  const iconColor = useColorModeValue('white', 'white');//Navbar

  
	const  { hstatRef, hstat, setHstat, accountRef, account, setAccount, signerRef, signer, setSigner, statInfo, setStatInfo,challengeJson,setChallengeJson }  = useEthereumContext();
	//console.log("Neuaufruf ======> ",hstat);
	const [ events, setEvents ] = useState([]);	

	const [ imageList, setImageList ]=useState([]);
	//const [ imageList2, setImageList2 ]=useState([]); //ipfsFull
	//const [ imageList3, setImageList3 ]=useState([]); //ticket nft
    const [imageListAtrs,setImageListAtrs]=useState([]);	
	const [neighbors,setNeighbors]=useState([-1,-1]);
	const [neighborsAtr,setNeighborsAtr]=useState([]);
    const [atrbool,setAtrbool]=useState([]);
//	const [statInfo,setStatInfo]=useState("");
	const [shown0,setShown0]=useState(false);
	const [shown1,setShown1]=useState(false);
	const [headn0,setHeadn0]=useState(true);
	const [headn1,setHeadn1]=useState(true);
	const [img1n0,setImg1n0]=useState(false);
	const [img1n1,setImg1n1]=useState(false);
	const [img2n0,setImg2n0]=useState(false);
	const [img2n1,setImg2n1]=useState(false);	
	const [inputn0,setInputn0]=useState(true);
	const [inputn1,setInputn1]=useState(true);
	const [buttn0,setButtn0]=useState(true);
	const [buttn1,setButtn1]=useState(true);
	
    const [chStat,setChStat]=useState(0); //ChooseStatus 0=keins,1=links 2=zwei, 3=zugeordnet(1,2)  
/*.................
  const [signatur,setSignatur] = useState('');
  const [eventId,setEventId] = useState('');
  const [evntId,setEvntId] = useState('');

//-----------------------------------------------------------	
    const [formInput, updateFormInput] = useState({ solution: '' });
*/
	
const [value0, setValue0] = React.useState('');
const [value1, setValue1] = React.useState('');
const [memnotic, setMemnotic] = useState(' '); 

useLayoutEffect( ()=> {
	if ( events.length===0 && (hstatRef.current===true||hstat===true)) {
	let pm = handleFetchEvents();
	console.log("handleFetchEvents.promise: ",pm);
	}
},[hstatRef.current]);

const handleChange0 = (e) => {
		if (e.target.value!='') {setValue0(e.target.value);}
		console.log("e0: ",e);
	};

const handleChange1 = (e) => {
		if (e.target.value!=''){setValue1(e.target.value);}
		console.log("e1: ",e);
};	
/*xxx
  useEffect(() => {
   if (hstatRef.current !== true) {
	   console.log('hstatRef.current useEffect: ',hstatRef.current);
	   localforage.getItem('hstat').then (hs => {
		if (hs !== null){
			hstatRef.current=hs;
		} 
		else {
			hstatRef.current=false;
			localforage.setItem('hstat',false).then(() => {
				//console.log('hstat false stored successfully!');
			}).catch(error => {
				console.error('Error storing hstat false:', error);
			});		   
			hstatRef.current=false;	
		}
	   }).catch(error => {console('Error getItem hstat ', error)});
    //hstatRef.current=(localStorage.getItem('hstat')!==null)?JSON.parse(localStorage.getItem('hstat')):false;
	console.log("hstatRef.current",hstatRef.current);
   setHstat(hstatRef.current);
   };
  },[hstatRef.current]);
*/	
const handleFetchEvents = async () => {	 //async 

//2808	let gU= await getuser();
//2808	console.log("getuser: ",gU);
	
//2808	if (gU.statInfo === "0x0000000000000000000000000000000000000000000000000000000000000000"){
//2808		setStatInfo(gU.statInfo);
//2808	};
	console.log("**********************handle fetchEvents=hstatRef.current: ",hstatRef.current,",",typeof hstatRef.current);  
	//!!if (!hstatRef.current === true) { console.log("return[] wegen !hstatRef.current === true und kein Aufruf fetchEvents; hstatRef.current: ",hstatRef.current);return [];}
	console.log("Aufruf fetchevents ");
	let ret = await fetchEvents (9998,account);
	console.log("typeof(ret): ",typeof(ret));
	console.log("ret: ",ret);
	if (typeof(ret.ev)== 'object' && ret.ev.length>0) {
		let tlen=ret.ev.length;
		let tSrcs=[];
		let tSrcs2=[];
		let tSrcs3=[];
		let tatrbool=[];
		// 128			64			32			16			8			4			2			1
		// 												4.B=WKFg	3.B=Lokgef	2.B=Stadtgef 1.B=Suche Stadt
		//															Suche WKF	Suche Lokation
		ret.ev.map((ev,idx)=> {
		  console.log(ev[0]);
		  if ( typeof atrbool[idx] === 'undefined') {		//typeof atrbool[ev[0]] === 'undefined') {
			  console.log("XXX85 ev[idx]: ",idx,",",ev);
			  tatrbool.push([false,false,false,false,false,false,false,true]) //rate Stadt bit0
		  }
		  else {console.log("XXX85 idx , ev[idx]: ",idx,",",ev[idx]);
			tatrbool.push([ 
			atrbool[idx][0],atrbool[idx][1],atrbool[idx][2],atrbool[idx][3],
			atrbool[idx][4],atrbool[idx][5],atrbool[idx][6],atrbool[idx][7]
			])
		  };  

		  tSrcs.push(ev[5]);
		  if ((ev.length>14) && (typeof ev[9] !== 'undefined') &&(typeof ev[15]!=='undefined')){
			  tSrcs2.push(ev[9]);// eb[9]  ipfsFull 
			  tSrcs3.push(ev[15]);// ev[15] ticket nfs
		  } else 
		  {
			  tSrcs2.push("");
			  tSrcs3.push("");
		  };
		  
		});
		if (neighbors[0] === -1) {
			//xxx setShown0(false);
			//xxx setHeadn0(false);
			//xxx setInputn0(false);
			//xxx setButtn0(false);
			} else {
			//xxx setShown0(true)
			};  
		if (neighbors[1] === -1) {
			//xxx setShown1(false);
			//xxx setHeadn1(false);
			//xxx setInputn1(false);
			//xxx setButtn1(false);
			} else {
			//xxx setShown1(true);
			}			
//========================================================= Spielestatus neu setzen und lokal speichern
		let tstatInfo=""; console.log("tatrbool: ",tatrbool);
		tatrbool.map( (u8a) => {
			tstatInfo = tstatInfo+u8arr2hex(u8a);
		});
		tstatInfo = ("0x"+tstatInfo+"0000000000000000000000000000000000000000000000000000000000000000").substring(0,66);
		console.log("geoegg129 tstatInfo: ",tstatInfo);
	    console.log("Length: ",tstatInfo.length);	
		tstatInfo = atrbool2statInfo(tatrbool);
		console.log("atrbool2statInfo(atrbool): ",atrbool2statInfo(tatrbool));

		localforage.setItem('statInfo',tstatInfo).then(() => {
		console.log('statInfo stored successfully!');
		setStatInfo(tstatInfo);		
		setAtrbool(tatrbool);
		setImageList(tSrcs);
		setEvents(ret.ev);
				return ret.ev;
		
		
		}).catch(error => {
			
			console.error('Error storing statInfo:', error);
			
				return [];
		});
		//!!!!localStorage.setItem('statInfo',tstatInfo);
//========================================================
		//xxx setStatInfo(tstatInfo);		
		//xxx setAtrbool(tatrbool);
		//xxx setImageList(tSrcs);
		//xxx setImageList2(tSrcs);//ev[9]
		//setImageList3(tSrcs3);//ev[15]
		//xxx setEvents(ret.ev);
//Test
//	  const modifyStatInforet =  modifyStatInfo(3,"0x123456789012345678901234567890123456789012345678901234567890ffff","EE");
//	  console.log("Test modifyStatInforet(0,0x123456789012345678901234567890123456789012345678901234567890ffff,EE\n: ",modifyStatInforet);

		return ret.ev;
		
    } else {
		return [];
	}
	
	console.log("fetchEvents(9998,..): ",ret);
};
///////!!!!!!!!!!!!!!!!!!!!!! ret promise

  useEffect(() => {
    if ( signer) {
	 localforage.getItem('statInfo').then (stinf => {		
		//console.log('hstat:', hs);
		if (stinf === null){
			const sI="0x0101010101010101010101010101010101010101010101010101010101010101";
			localforage.setItem('statInfo',sI).then(() => {
				setStatInfo(sI);
				//console.log('statInfo stored successfully!');
			}).catch(error => {
				console.error('Error storing statInfo:', error);
			});
		} 
		else {
			setStatInfo(stinf);
		}
	}).catch(error => {
			console.error('Error getItem hstat:', error);
	});	
    let ret = handleFetchEvents();
	console.log("ret: ",ret);
	if (ret&&ret.length>0) {
		console.log ("ret handleFetchEvents: ",ret);
		//handleFetchImgs();
		let tSrcs=[];
		let timgsAttr=[];
		let tlen=ret.length;
		ret.map((ev)=> {
		tSrcs.push("https://werlins.de/images/"+ev[9]);console.log("ev[9]: ",ev[9]);
		timgsAttr.push(0);
		});
		//urls.map((url) => {
		// tSrcs.push("https://werlins.de/images/"+url.substr(1));
		//});
		setEvents(ret);
		console.log(tSrcs);
	//	setImageList(tSrcs);
	}

	}
  }, [signer]) 

//----------------------------------- ImageGallery
//https://www.dhiwise.com/post/guide-to-optimizing-image-loading-with-react-preload

  const [preloadedImages, setPreloadedImages] = useState([]);
  
  const preloadImage = (src) => {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.src = src;
		img.onload = resolve;
		img.onerror = reject;
	});
  };

  useEffect(() => { 
  if (imageList.length> 0) { // urls.length) {
    const preload = async () => {//ilist
      const promises = imageList.map((src) => preloadImage(src));
      const images = await Promise.all(promises);
      setPreloadedImages(images);
    };

   // preload();
//	console.log("preloadedImages: ",preloadedImages);
  }
  }, [imageList]);

//const bilder = useMemo(()=> newPics(),[events]); 
/*

//======================================================================
  async function handleSubmit(e) {
    e.preventDefault();
	//handleFetchEvent(evntId);
	
	
	setStatus('loadEvent');
	setEventId(evntId);
	
    /*
	try {
      await submitForm(eventId);
      setStatus('success');
    } catch (err) {
      setStatus('typing');
      setError(err);
    }
	*/
/*  }

//
  useEffect(() => {
    if ( typeof(events)==='object' && events.length>0) {
      //newPics();
		
    }
  }, [ events]) 
  
  const newPics = () =>{
	if (events !== []) {
		let cnt=0;
		let tmpPics=[];
		let tmpAtrs=[];
		events.map((im,idx) => {
			tmpPics.push(im[9]);
			tmpAtrs.push(1)
console.log("events.map idx: ",idx);

//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! cors fehler fetch werlins.de
			/*if (cnt === 0 ) {cnt++;
			  console.log("substr klUrl",im[9]);
			  try {
				  fetch(im[9]).then(response => response.blob())
				  .then(blob => {
					// Convert BLOB to base64
					let reader = new FileReader();
					reader.readAsDataURL(blob); 
					reader.onloadend = function() {
						// Write base64 to localstorage   
						let base64data = reader.result;     
						localStorage.setItem('image_' + cnt, base64data);
						console.log('Saving image ' + cnt + ' to localstorage');
						cnt++
					};
				})
				} 
			catch (e) {
				 console.log(e)
			}
			}; 
			*/
/*		});
		console.log("tmpPics",tmpPics);
		console.log("tmpPics",tmpAtrs);
		setPhotos(tmpPics);
		setImageListAtrs(tmpAtrs);
	}
  }

  //////===========> const bilder = useMemo(()=> newPics(),[events]);
  

//------------------------------------------------
//https://stackoverflow.com/questions/66575645/initialize-eager-load-react-component-in-background-on-app-start-for-later-usa
//https://stackoverflow.com/questions/66575645/initialize-eager-load-react-component-in-background-on-app-start-for-later-usa
//--------------------------------------------------
*/
		//		0       1     2     3		 4   	 5		6     7
		//[ in-neigh|rate S|SiP  |S gerat|rate Lok|Lokip|Lok ok| n1 ]   
		// 0=Anzeigeist in neighbors,1=rate Stadt
		//  2=in Prüfung,3 Stadt geraten,4=rate Lokation,
		//	5=Lokation in Prüfung, 6=Lokation geraten
		//	 7=ist in neigbors 1	
	const handleCheckSolution = async (nr,ind) => {	 //{_eventId,_addr,_solin}	
	if (ind <0 ) { 
		if (nr === 0) {//xxx setValue0('')
		}
		else {//xxx setValue1('')
		};
		return;
	};

let value=value0;
if (nr===1) {
	value=value1;
	//xxx setValue1('');
} else {
	//xxx setValue0('');
}	

	if (value!=='') {
	 	
	 if ((ind === neighbors[0]) || (ind  === neighbors[1]))	{
		const sigret= await retSignMessage(ind.toString());
		console.log("sign(",ind.toString(),account,":",sigret)
		let tatrbool = [];
		let tatrboolInd=[];
		let n01 = false; // n0
		let smT = false; // nicht selbe Stadt
		if (ind === neighbors[1]) { // rechts belegt->links auch
			n01=true;
			if ((events[ind][7] === neighbors[0])||(events[neighbors[0]][7]===neighbors[1])) {// gleiche Stadt
				smT = true;
			} 
		}
		else {
			if ((neighbors[1] > 0)&&(( events[ind][7] === neighbors[1])||(events[neighbors[1]][7]===neighbors[0]))) { //gleiche Stadt
				smT = true;
			}
		}	
//!!!button deactivieren
		//		0       1     2     3		 4   	 5		6     7
		//[ in-neigh|rate S|SiP  |S gerat|rate Lok|Lokip|Lok ok| n1 ]

		atrbool.map ((val,i) => {
			if (ind === i) { //		  
			//soll Lok gelöst werden?  Lokation in Prüfung 2 
			  if (atrbool[ind][4]) {
				tatrboolInd.push([ //              LiP                   Sgel
				smT,atrbool[ind][1],atrbool[ind][2],true,false,true,atrbool[ind][6],atrbool[ind][7]]) //stadt gelöst, L in P
				tatrbool.push(tatrboolInd);
			  }
			  else 
				  //soll Stadt gelöst werden? in Prüfung =>[5] (32)
			  if (atrbool[ind][1]) {
				tatrboolInd.push([ //          rateL                 SiL  rateS
				smT,false,true,false,false,false,false,atrbool[ind][7]]); //Stadt in Pr
				tatrbool.push(tatrboolInd);
			  }
			  else { console.log("264Fehler handleCheckSolution,atrbool[",ind,"]:",atrbool[ind]);
				tatrbool.push(val);
			  }
			  
			}  
			else tatrbool.push(val);
		});
		if (ind === neighbors[0]) {
			if ((atrbool[ind][2])||(atrbool[ind][5])){ //Lok-in-pr	|| Stadt in Pr 
			//xxx setButtn0(false);
			}

		}
		if (ind === neighbors[1]) {
			if ((atrbool[ind][2])||(atrbool[ind][5])) { //Lok-in-pr	|| S i P
			//xxx setButtn1(false);
			}
		}
//setzen,da Funktion asynchron Zeit

//========================================================= Spielestatus neu setzen und lokal speichern
		let tstatInfo1="";
		let tstatInfo=""; // zwei wege zum test
		tatrbool.map( (u8a) => {
			tstatInfo1 = tstatInfo1+u8arr2hex(u8a);
		});
		tstatInfo1 = ("0x"+tstatInfo+"0000000000000000000000000000000000000000000000000000000000000000").substring(0,66);
		console.log("geoegg129 tstatInfo1: ",tstatInfo1); //1. weg
	    console.log("Length: ",tstatInfo.length);	
		tstatInfo = atrbool2statInfo(tatrbool);
		console.log("atrbool2statInfo(atrbool): ",tstatInfo);

		localforage.setItem('statInfo',tstatInfo).then(() => {
		//console.log('statInfo stored successfully!');
		}).catch(error => {
			console.error('Error storing statInfo:', error);
		});		
//========================================================
		//xxx setStatInfo(tstatInfo);		
		//xxx setAtrbool(tatrbool);
// Wert überprüfen
//		0       1     2     3		 4   	 5		6     7
//[ in-neigh|rate S|SiP  |S gerat|rate Lok|Lokip|Lok ok| n1 ] 

		const	rets = await checkSolution(ind,account,tatrbool,value,sigret);
		console.log("rets: ",rets);
//atrbool setzen 
		let tatrbool2=[]
		tatrbool.map ((val,i) => {
			if (ind === i) {
				if (rets.data.message === "Die Lösung stimmt") {
				  if (tatrbool[ind][2]) {// Stadt in Prüfung	=> 5false, 6false, 3true, 4true				
				   tatrbool2.push([ 
				   atrbool[ind][0],false,false,true,true,false,false,atrbool[ind][7] 
				   ])
				  }
				  else { 
				  if (tatrbool[ind][5]) {
				  // (Location in Prüfung =>
				   tatrbool2.push([ 
				   atrbool[ind][0],false,false,true,false,false,true,atrbool[ind][7]
				   ])
				  }
				  else { 
				   console.log("339Fehler handleCheckSolution,Lsg ok aber !atrbool[",ind,"]:",atrbool[ind]);
				   tatrbool2.push(val);
				  }					  
				  }
				}
				else {//stimmt nicht
				  if (tatrbool[ind][2]) {// Stadt in Prüfung	
					tatrbool2.push([ //                           rtSt      
				    atrbool[ind][0],false,false,false,false,false,false,atrbool[ind][7]
				  ])
				  }
				  else 	
				  if (tatrbool[ind][2]) {// Lok in Prüfung	
					tatrbool2.push([ //          rtLo,Sger
				    atrbool[ind][0],true,false,true,false,false,false,atrbool[ind][7]
			        ])
				  }
				  else { 
				   console.log("358Fehler handleCheckSolution,Lsg falsch aber !atrbool[",ind,"]:",atrbool[ind]);
				   tatrbool2.push(val);
				  }					  

				}
				
			}  
			else tatrbool2.push(val);
		});
//========================================================= Spielestatus neu setzen und lokal speichern
		console.log("tatrbool2.length: ", tatrbool2.length);
		let tstatInfo2 = atrbool2statInfo(tatrbool2);
		console.log("atrbool2statInfo(atrbool): ",atrbool2statInfo(tatrbool2));
	
		localforage.setItem('statInfo',tstatInfo2).then(() => {
		//console.log('statInfo stored successfully!');
		}).catch(error => {
			console.error('Error storing statInfo2:', error);
		});
//========================================================
		//xxx setStatInfo(tstatInfo2);		

		//xxx setAtrbool(tatrbool2);
		console.log("tatrbool: ",tatrbool2);
		if (nr===0){//xxx setValue0('');
		}
		if (nr===1){//xxx setValue1('');
		}
		console.log("rets.data: ",rets.data);
		alert(rets.data.message);
	  }
	}
};


	
//----------------------------------

const verifyMessage = async ( m, address, sg ) => {
  console.log("vM address: ",address);
  try {
    const signerAddr = await ethers.verifyMessage(m, sg);
    if (signerAddr !== address) {
		console.log("signerAddr: ",signerAddr);
		console.log("address: ",address);
      return false;
    }
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
 };
 
 const getuser = async () =>{
   try {
	const sigret = await retSignMessage("1");
	return getUser(account,sigret)
   } catch (e) {
	   return []
	   console.log("error getuser: ",e);
   }
 };

/*2808
 function setuser(statInfo,nonce,timestamp){
   try {
	const sigret = retSignMessage("1");
	setUser(account,statInfo,nonce,timestamp,sigret);
   } catch (e) {
	   console.log("error getuser: ",e);
   }
 };
2808 */  
 const retSignMessage = async (message) => {
  try {
	      if (signer&&account) {
   const msg =new util.TextEncoder().encode(message);
   const sign = await signer.signMessage(msg);
	console.log("retSignMessag: ",sign);
	return sign;
		  }
  } catch (error) {
	console.log("Error retSignMessage: ",error);
	return null;  
  }
 };
 

const signMessage = async (message) => {
  try {
    if (signer&&account) {
	  const msg =new util.TextEncoder().encode(message);
	  //const msg = ethers.toUtf8Bytes(message);
	  console.log("signer: ",signer);
      const signature = await signer.signMessage(msg);
      setMemnotic(signature);
	  console.log("Signatur: ",signature);

//------------------------------
//0x487a5e78ab60a48336c3a4a44b8ea165f10dff30fd3b7422b5e7bb9c7dd27cc048edd39a0f728e0c1ebed13f3cdc8129c04f609606810380993a78622067fd6a1c
//"0x0f2c3b2f9eeaceea648f6c281f3d7062fbf74e37e634e87deb83d3f7d3c2747e1caada4e56ea300d07f59ba54694b0c033d60d243215cfdfc904cccd562a59be1c"	
//---------------------------------------------	
	  const decsig = await verifyMessage(msg,account,signature);
	  console.log("Decodiert: ",decsig);
	  const retcod = await checkAddress(ethers.toUtf8String(msg),account,signature);
	  //setDecodsign(decsig);	  
	  //setRtc(retcod.data);
	  
    }
  } catch (err) {
    console.log(err);
  }
}

//neighbors[0]===parseInt(events[neighbors[1]][7]))
   const clickNeighbor = async (ev0) => {
	   console.log("=>ev0,tof,neighbors[0]: ",ev0," ",typeof neighbors[0]," ", neighbors[0]);
    if ((ev0>=0) && (ev0!==neighbors[0]) &&
		(ev0 !==neighbors[1]) ) {//Aufgenommene ausschließen
		let newImageListAtrs=[];
		let tatrbool=[];
		let ev1 = neighbors[0];//linke Auswahl wird rechte (neue Auswahl linke)
//-----------------------------------------------------------------------------------------
//		0       1     2     3		 4   	 5		6     7
//[ in-neigh|rate S|SiP  |S gerat|rate Lok|Lokip|Lok ok| n1 ] 
//-------------------------------------------------------------------------------------		
		let tshown0=false;
		let theadn0=false;
		let timg1n0=false;
		let timg2n0=false;
		let tinputn0=false;
		let tbuttn0=false;

		let tshown1=false;
		let theadn1=false;
		let timg1n1 = false;
		let timg2n1 = false;
		let tinputn1=false;
		let tbuttn1=false;	

		if (ev1 !== -1) { //n0 war besetzt
			tshown1=true;
			theadn1=headn0;
			timg1n1=img1n0;
			timg2n1=img2n0;
			tinputn1=inputn0;
			tbuttn1=buttn0;				
		};
		try {
		  atrbool.map ((val,i) => {
		  if ((neighbors[0]>=0)&&(neighbors[0] === i)) { // 0 setzen nur Platztausch links nach rechts
				tatrbool.push([ 
				true,atrbool[i][1],atrbool[i][2],atrbool[i][3],atrbool[i][4],
				atrbool[i][5],atrbool[i][6],atrbool[i][7]]);
				console.log("(neighbors[0]>=0)&&(neighbors[0] === i),neighbors[0],i: ",
				neighbors[0],",",i,",",tatrbool);
			  }
			  else { ////
			  if ( i === ev0) {	// neuer linker Nachbar
				tshown0=true;
				if (!atrbool[ev0][3]) {// Stadt noch nicht geraten
					tatrbool.push([ 
					false,atrbool[i][1],atrbool[i][2],atrbool[i][3],atrbool[i][4],
					atrbool[i][5],atrbool[i][6],atrbool[i][7]]);
					theadn0=true;
					timg1n0=true;
					timg2n0=false;
					tinputn0=true;
					tbuttn0=true;					
				} 
				else {
					if (!atrbool[ev0][6]) {// Lok noch nicht geraten
						tatrbool.push([ 
						false,atrbool[i][1],atrbool[i][2],atrbool[i][3],atrbool[i][4],
						atrbool[i][5],atrbool[i][6],atrbool[i][7]]);				
						theadn0=true;
						if (events[ev0].length<13) {
							timg2n0=false;
							timg1n0=true;
						} else {
							timg2n0=true;
							timg1n0=false;
						}
						tinputn0=true;
						tbuttn0=true;				
					} else { // fertig
						tatrbool.push([ 
						false,true,true,true,true,
						atrbool[i][5],atrbool[i][6],atrbool[i][7]]);				
						theadn0=true;				
						tinputn0=false;
						tbuttn0=false;				
					}
				}
			  }
			  else { // i nicht linker noch rechte Auswahl
				tatrbool.push([ 
				atrbool[i][0],atrbool[i][1],atrbool[i][2],atrbool[i][3],atrbool[i][4],
				atrbool[i][5],atrbool[i][6],atrbool[i][7]])			  
			  }
			  } /////
		})
		} catch (e) {console.log("XXX539: ",e) };	
//=============================================================> Aufruf preSelEvId(ev0,account,tatrbool,sigret(ev0))		
		 const sigret = await retSignMessage(ev0);
		 let psei = await preSelEvId(ev0,account,tatrbool,sigret); //!!!
		 console.log("preSelEvId return: ",psei);
		 
		 setAtrbool(tatrbool);
		 setNeighbors([ev0,ev1]);
		if ((ev1 !== -1)&& 
			((events[ev0][6] === events[ev1][7])||(events[ev0][7] === events[ev1][6]))) {//gleiche stadt
			theadn0 = true; theadn1=true;
		}
		else {
			theadn0 = false; theadn1=false;
		}			

		//xxx setShown0(true);
		//xxx setHeadn0(theadn0);
		//xxx setImg1n0(timg1n0);
		//xxx setImg2n0(timg2n0);
		//xxx setInputn0(tinputn0);
		//xxx setButtn0(tbuttn0);

		//xxx setShown1(tshown1);
		//xxx setHeadn1(theadn1);
		//xxx setImg1n1(timg1n1);
		//xxx setImg2n1(timg2n1);
		//xxx setInputn1(tinputn1);
		//xxx setButtn1(tbuttn1);		
		
		console.log("atrbool: ",atrbool);
		console.log("tatrbool: ",tatrbool);
	}
   };
   //hstatRef.current=(localStorage.getItem('hstat')!==null)?JSON.parse(localStorage.getItem('hstat')):false;
   //console.log("hstatRef.current: ",hstatRef.current);
// size={isNotSmallerScreen ? 'lg' : 'md'}
  return(
<>

{hstatRef.current && typeof account === 'string' && account.length>0 && 
<Flex position="sticky" top="0"  bg='gray.2200'  columns={[2, 2, 2]} spacing='40px' >
  <Box max-height="20vh">	
	<VStack align='stretch'  >
		<Spacer spacing='10px'/>
		<Center>
			  <Image


			    rounded="10px"

				objectFit='cover'
				src={neighbors[0]===-1?"https://werlins.de/images/medium-pexels-pixabay-415919.jpg":imageList[neighbors[0]]}
				alt='weli'
			  >
			  </Image>
		</Center>
		<Center>
			   <Input 
				fontSize={['sm', 'md', 'lg', 'xl']}	
			    bg='LightSkyBlue' color='black'
				h='1.2em'
				htmlSize={1}
				value={value0}
				placeholder="Lösung?"
				_placeholder={{ opacity: 1.0, color: 'black' }}
                onChange={handleChange0}
			   >
			   </Input>
		</Center>
		<Center>
			  <Button 
				fontSize={['sm', 'md', 'lg', 'xl']}				  
				h='1.2em'
				bg='LightSkyBlue' color = 'black'
				onClick={() => handleCheckSolution(0,neighbors[0])}
			   >
				Abschicken
			   </Button>	
		</Center>
	</VStack>
  </Box>	
	<Spacer spacing='10px'/>
  <Box max-height="20vh">
	<VStack align='stretch'  >	
		<Spacer spacing='10px'/>
		<Center w="100%">
			  <Image


			   rounded="10px"

				objectFit='cover'
				src={neighbors[1]===-1?"https://werlins.de/images/medium-pexels-pixabay-415919.jpg":imageList[neighbors[1]]}
				alt='weli'
			  >
			  </Image>
		</Center>
		<Center>
			   <Input
				fontSize={['sm', 'md', 'lg', 'xl']}			   

			    bg='LightSkyBlue' color='black'
				h='1.2em'
				value={value1}
				placeholder="Lösung?"
				_placeholder={{ opacity: 1.0, color: 'black' }}				
                onChange={handleChange1}
			   >
			   </Input>
		</Center>
		<Center>
			  <Button 
				fontSize={['sm', 'md', 'lg', 'xl']}			  
				h = '1.2em'
				bg='LightSkyBlue' color = 'black'
					onClick={() => handleCheckSolution(1,neighbors[1])}
			   >
				Abschicken
			   </Button>
		</Center>
	</VStack>
  </Box>	
</Flex> }
  
{!hstatRef.current && <ProtectedForm />}
{hstatRef.current && (events.length >0) &&
   <SimpleGrid columns={[1, 2, 3]} spacing='10px'>
    {events.map((event, index) => (
	    <Box bg='tomato' fontSize={['sm', 'md', 'lg', 'xl']}>
			{event.length>1 && (
			  <>
			  <Center w='100%' h='1.5em' bg='white' color='black'>
				<Heading fontSize={['sm', 'md', 'lg', 'xl']} mb={1}>{event[0]}</Heading> 
			  </Center>	
			  <Center  bg='white' color='green'>
				<Image
					rounded="10px"
					objectFit='cover'
					src={imageList[event[0]].substring(0,26)+variant+imageList[event[0]].substring(32)}
					alt='weli'
					onClick={() => clickNeighbor(event[0])}
					>
				</Image>
			  </Center>		  

			  </>
			)
			}
		</Box> 
	))
	} 
   	</SimpleGrid>
  }


</>
)
}
/* 
	<VStack align='stretch'>
	<Center w='100%' h='50%' bg='white' color='black'>
				<Text>Vorgänger des linken Bilds</Text> 
		  </Center>	
	}	
			  <Center w='100%' h='10%' bg='white' color='green'>
			   <Input
				htmlSize={4} width ='100%'
				value={value}
				placeholder="Koordinaten"
                onChange={handleChange}
			   >
			   </Input>
			  </Center>			  
			  <Button
					colorScheme='blue'
					onClick={() => handleCheckSolution(neighbors[1])}
			   >
				Lösung einsenden
			   </Button>
	
	</VStack>
  
Gallery ohne Eingabe vor  </> und oberhalb image
			  <Center w='100%' h='50%' bg='white' color='black'>
				<Heading mb={1}>{event[0]}</Heading> 
			  </Center>	
			  
			  <Center w='100%' h='10%' bg='white' color='green'>
			   <Input
				htmlSize={4} width ='100%'
				value={value}
				placeholder="Wo ist das? (Name oder Koordinaten)"
                onChange={handleChange}
			   >
			   </Input>
			  </Center>			  
			  <Button
					colorScheme='blue'
					onClick={() => handleCheckSolution(index)}
			   >
				Lösung einsenden
			   </Button>






		<img 
		onClick={() => {
			console.log("imageListAtrs[index]",imageListAtrs[index]);
			setImageListAtrs(imageListAtrs.slice(0,index),
				1-imageListAtrs[index],
				imageListAtrs.slice(index[0]+1))}}		
          key={index}
          src={src}
          alt={`Gallery image ${index}`}
          placeholderSrc={preloadedImages[index] || "placeholder.png"}
        />




<Wrap px="1rem" spacing={4} justify='center'>
 
    {events.map((event, index) => (
	    <WrapItem
		boxSize='300px'
		maxW='sm' borderWidth='0px' borderRadius='lg' overflow='hidden'		
	          boxShadow="base"
              rounded="1px"
              overflow="hidden"
              bg="white"
              lineHeight="0"
              _hover={{ boxShadow: "dark-lg" }}
        >
		<VStack  align='stretch'> 
		  <Center w='100%' h='25%' bg='tomato' color='white'>
      <Heading mb={1}>{event[0]}</Heading> 
		  </Center>		  
			<Image
			   rounded="10px"
				objectFit='cover'
				src={srcdata}
				alt='weli'
				
			  >
		   </Image>
		
            {event.length>1 && (
			  <>
			  <Image
			   rounded="10px"
			   boxSize= {imageListAtrs[event[0]]?'600px':'300'}
				objectFit='cover'
				src={photos[event[0]]}
				alt='weli'
				onClick={() => {setImageListAtrs(imageListAtrs.slice(0,event[0]),1-imageListAtrs[event[0]],imageListAtrs.slice(event[0]+1));
				console.log("imageListAtrs",imageListAtrs)}}
			  >
			  </Image>
			  <Center w='30%' h='1%' bg='white' color='green'>
			   <Input
				htmlSize={4} width ='auto'
				value={value}
				placeholder="Koordinaten"
                onChange={handleChange}
			   >
			   </Input>
			  </Center>			  
			  <Button
					colorScheme='blue'
					onClick={() => handleCheckSolution(index)}
			   >
				Lösung einsenden
			   </Button>
			  </>
			)
			}
		 </VStack>
		</WrapItem> 
		  ))
	}
  	</Wrap>

  </>
  
  )
}
*/