import React, { useState, useEffect,useLayoutEffect, useRef } from 'react'
import { Buffer } from "buffer";
import { ethers, Wallet } from 'ethers';
import { Box, Button, Text, Center } from '@chakra-ui/react'
import { useEthereumContext } from '../ethereumContext/EthereumContext'
import NetworkDisplay from './NetworkDisplay'
import {captchaChallenge } from '../../utils/actions';
import localforage from 'localforage';

export default function ConnectWallet() {
 const bip39 = require('bip39'); 
 const util= require('util');

 const   { hstatRef, hstat, setHstat, accountRef, account, setAccount, signerRef, signer, setSigner, statInfo, setStatInfo,challengeJson,setChallengeJson }
	= useEthereumContext();
 const [connected, setConnected] 			 	= useState(false);
//prüfen ob enable obsolet 
 const [enable, setEnable] 						= useState(false);
 
 const [pw, setPw] 								= useState("2beers4you");
 const [privateKey,setPrivateKey] =useState("");//0xf9c90232374c72e6f1fc7b39eff7764714c461373fbcd17dfc9ea7132a2d546b");
 
 const [memnonic,setMemnonic]=useState();
 const busyRef=useRef(false);
 //	console.log("typeof signer: ",typeof signer);
 //const isSignerRef=useRef(typeof signer === 'object'); 
//------------------------------------------------------------------------------
useLayoutEffect(() => { //Wallet öffnen oder erstellen
 if (connected ) {  
	busyRef.current=false;
	console.log("typeof(signer)",typeof(signer),signer);
	encryptSigner(pw,signer); //Wallet ist als signer offen und wird verschlüssel gespeichert
 } else {
	if (!busyRef.current) {
		busyRef.current=true;
		cWallet();
	}
  }
}, [connected]);
//-------------------------------> Wallet speichern
const encryptSigner = async (pw,signer)=>{
	//console.log("pw: ",pw);
	const esjson = await signer.encrypt(pw);
	localforage.setItem('encsigner',esjson).then(() => {
		console.log('encsigner stored successfully! ');
	}).catch(error => {
		console.error('Error storing encsigner :', error);
	});
}
//-------------------------------
 const cWallet = async => {
	if (!window.ethereum) { //client
		busyRef.current=false;
		return
	}

	try {
		window.Buffer = window.Buffer || Buffer;
		let sng=null;
		// ist Wallet (signer als 'encsigner' verschlüsselt im Speicher?
		let encS=null;
		localforage.getItem('encsigner').then(data => { //ist im Speicher
			encS=data;
			console.log('encS:', encS); 
			sng= ethers.Wallet.fromEncryptedJsonSync(encS,pw);//zurückgeladen und decrypted -> signer 
			gadr(sng); 		//account und signer extrahieren und hstat aktualisieren
		}).catch(error => { //ist nicht im Speicher
			console.log('encsigner nicht gespeichert:');
			// neue Wallet erstellen
			const rnd128=ethers.sha256((ethers.randomBytes(16))).toString().substring(2,34);
			//console.log("rnd128: ",rnd128);
			const mnem = bip39.entropyToMnemonic(rnd128);//'00000000000000000000000000000000');//generateMnemonic();
			console.log('Memnonic: ', mnem);
			sng = ethers.Wallet.fromPhrase(mnem); 
			//neue Wallet verschlüsslt abspeichern
			encryptSigner(pw,sng);
			//
			//sng=new Wallet(privateKey); alternative wäre Wallet aus private Key erstellen
			console.log(`Address: ${sng.address}`);
			console.log(`Private Key: ${sng.privateKey}`);
			alert('Ihr Spielstand wird unter ihrem Account in der eggcoin- bzw. goChain-Blockchain festgehalten, der aus den folgenden 12 Worten berechnet wird! bitte zur Wiederherstellung (z.B. bei Rechnerwechsel) unbedingt merken und geheim halten: '+ mnem);
			//!!setMemnonic(mnem); set bringt Unterbrechung und neuer Bildschirmaufbau!! --> keine set-Aufrufe
			hstatRef.current=false; //noch keine Prüfung Mensch/Maschine
			localforage.setItem('hstat',hstatRef.current).then(() => {
				console.log('hstat stored successfully!: ',hstatRef.current);
				gadr(sng);	//account und signer extrahieren und hstat aktualisieren			
			}).catch(error => {
				console.error('Error storing hstat :', error);
			});				
		})
		
	} catch (error) {
			window.alert('Kein Wallet Zugriff!')
			console.log(error)
	}
};

const gadr = async (sng)=>{
	busyRef.current=false;
	const a = await sng.getAddress();
	accountRef.current=a; //vermeiden set-Kommandos
	//account und signer abspeichern (asynchron -> verschachtelt!)
	localforage.setItem('account',a).then(() => {
		//console.log('account stored successfully!: ',a);
		localforage.setItem('signer',sng).then(() => {
			setHstat(hstatRef.current);
			setSigner(sng);
			setEnable(true);
			console.log('signer stored successfully!: ',sng);
			setConnected(true);//->nächste Aufrufe->
			setAccount(a);
		}).catch(error => {
		console.error('Error storing signer :', error);
		});	
	}).catch(error => {
		console.error('Error storing account :', error);
	});
}
//--------------------------------------------------------------------------------------------------------------------
 useEffect(() => {
   if (typeof(account)==='string' && account.length>0) {
		accountRef.current=account;
		// Retrieve data
		localforage.getItem('hstat').then (hs => {		
			//console.log('hstat:', hs);
			if (hs === true | false){
				hstatRef.current=hs;
				setHstat(hs);
			} 
			else {
				hstatRef.current=false;
				localforage.setItem('hstat',false).then(() => {
					console.log('hstat false stored successfully!');
					setHstat(false);
				}).catch(error => {
					console.error('Error storing hstat false:', error);
					setHstat(false);
				});
			}
		}).catch(error => {
				hstatRef.current=false;
				console.error('Error getItem hstat:', error);
				setHstat(false);
		});	
   }
  }, [account]);

const signAccRef = async () => {
	console.log("===============================>signAccRef");
	console.log("account",typeof account," ", account);
	console.log("signer ",typeof signer," ", signer);
		try {
			if (signer  && account) {
				const msg = new TextEncoder().encode("message");
				const sig = await signer.signMessage(msg);
				console.log("sig: ",typeof sig,sig);
				return sig; 
			}
		} catch (err) {
			console.log("Error signAccRef: ",err);
		}
	}

const gChallenge = async () => {
	const sig = await signAccRef();	
	console.log("captchaChallenge(accountRef.current,sig): ",accountRef.current," ",sig);
	const retobj = await captchaChallenge(accountRef.current,sig); console.log("retoj von captchaCallenge: ",retobj);
	console.log("retobj: ",retobj)
	const ret=JSON.stringify(retobj); console.log(retobj);
	console.log("ret: ",ret)
	if ((retobj.nonce === undefined )||(Number(retobj.nonce)<2)) {
		console.log("retob.nonce undefined: ",retobj.nonce, ",",typeof retobj.nonce);
		hstatRef.current=false; 
		localforage.setItem('hstat',false).then(() => {
				//console.log('hstat false stored successfully!');
			setHstat(false);				
			}).catch(error => {
				console.error('Error storing hstat false:', error);
			});
		//localStorage.setItem('hstat',JSON.stringify(false));
	}
	else { 
		console.log("retobj.nonce: ",retobj);
		if (retobj.nonce !== undefined) {
			hstatRef.current=true;
			localforage.setItem('hstat',true).then(() => {
				console.log('gCallage nonce >=2 hstat true stored successfully!');
				setHstat(true);
			}).catch(error => {
				console.error('Error storing hstat true:', error);
			});
		}
		//localStorage.setItem('hstat',JSON.stringify(true));

	}	
	//console.log("setChallengeJson(ret):",ret);
	setChallengeJson(ret);//.ret);
}

useLayoutEffect(() => {
    console.log("Aufruf gChallenge mit sig (typeof) :",typeof signer);
		if (typeof(accountRef.current)==='string' && accountRef.current.length >0 
			&& typeof signer === 'object'){// 240926 typeof signer !!
			gChallenge();
		}
	}, [accountRef.current,signer]);//,hstatRef.current]);	

/*	
useLayoutEffect(() => {
  localforage.getItem('hstat').then(hs => {	
	if (hs === null) {
		hstatRef.current=false;
		localforage.setItem('hstat',false).then(() => {
			setHstat(false);
		}).catch(error => {
			console.error('Error storing hstat false:', error);
		});
	}else {
		hstatRef.current=hs;
		setHstat(hs);
	}
	console.log("hstatRef.current",hstatRef.current);
  }).catch(error => {
			console.error('Error storing hstat false:', error);
  });
},[]);
*/





  return (
    <Box fontSize={['sm', 'md', 'lg', 'xl']}>

       <>
		<Center>
          <Text color='white'>Ihre Account-Nr ist:  {account}  </Text>
		</Center>

        </>

    </Box>
  )
}
