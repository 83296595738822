import axios from 'axios';

export const captchaChallenge = async (acc,sig) => {
  try {
   const response = await axios.post(`https://werlins.de/lwm/api/captchaChallenge`, {
    acc, sig },
    {"headers": {
       'Content-Type': 'application/json',
    }
  })
    return response.data;
  } catch (e) {
    alert(e.response.data.message)
  }
}

export const verifyChallenge = async (data) => {

  try {
   const response = await axios.post(`https://werlins.de/lwm/api/verifyChallenge`, {
      data },
    {"headers": {
       'Content-Type': 'application/json',
    }
  })
    return response.data;
  } catch (e) {
    alert(e.response.data.message)
  }
}

export const edEvent = async (eventId, name, timestamp, info, ipfs,
			anzNr, nxtAnzNr, infoFull, ipfsFull, srcString,state, addr,sign) => { 
  try {
//localhost durch 172.29.49.97 ersetzt????

 // const response = await axios.post(`http://172.29.49.97:5000/api/check`, {
 const response = await axios.post(`https://werlins.de/lwm/api/edevent`, {
	eventId,  name, timestamp, info, ipfs,
			anzNr, nxtAnzNr, infoFull, ipfsFull, srcString,state, addr,sign},
    {"headers": {
       'Content-Type': 'application/json',
    }
  })

    return response
  } catch (e) {
    alert(e.response.data.message)
  }
}

export const getUser = async (_addr,_sigret) => {
  try {
//localhost durch 172.29.49.97 ersetzt????

 // const response = await axios.post(`http://172.29.49.97:5000/api/check`, {
 const response = await axios.post(`https://werlins.de/lwm/api/getuser`, {
	  _addr,_sigret
    },
    {"headers": {
       'Content-Type': 'application/json',
    }
  })

    return response
  } catch (e) {
    console.log("getUser error: ",e)
  }
}

export const setUser = async (_addr,_statInfo,_nonce,_timestamp,_sigret) => {
  try {
//localhost durch 172.29.49.97 ersetzt????

 // const response = await axios.post(`http://172.29.49.97:5000/api/check`, {
 const response = await axios.post(`https://werlins.de/lwm/api/setuser`, {
	_addr,_statInfo,_nonce,_timestamp,_sigret
    },
    {"headers": {
       'Content-Type': 'application/json',
    }
  })

    return response
  } catch (e) {
    console.log("setUser error: ",e)
  }
}

export const checkSolution = async (_eventId,_addr,_tatrbool,_solin,_sigret) => {
  try {
//localhost durch 172.29.49.97 ersetzt????

 // const response = await axios.post(`http://172.29.49.97:5000/api/check`, {
 const response = await axios.post(`https://werlins.de/lwm/api/checksolution`, {
		_eventId,_addr,_tatrbool,_solin,_sigret
    },
    {"headers": {
       'Content-Type': 'application/json',
    }
  })

    return response
  } catch (e) {
    alert(e.response.data.message)
  }
}


export const preSelEvId = async (eventId,addr,tatrbool,sigret) => {
  try {
//localhost durch 172.29.49.97 ersetzt????

 // const response = await axios.post(`http://172.29.49.97:5000/api/check`, {
 const response = await axios.post(`https://werlins.de/lwm/api/preSelEvId`, {
		eventId,addr,tatrbool,sigret
    },
    {"headers": {
       'Content-Type': 'application/json',
    }
  })

    return response
  } catch (e) {
    alert(e.response.data.message)
  }
}

export const checkAddress = async (msg,addr,sign) => {
  try {
//localhost durch 172.29.49.97 ersetzt????

 // const response = await axios.post(`http://172.29.49.97:5000/api/check`, {
 const response = await axios.post(`https://werlins.de/lwm/api/checkaddress`, {
      msg,
      addr,
	  sign
    },
    {"headers": {
       'Content-Type': 'application/json',
    }
  })

    return response
  } catch (e) {
    alert(e.response.data.message)
  }
}

export const fetchEvent = async (_eventId,_addr,_sign) => {
  try {
  // const response = await axios.post(`http://172.29.49.97:5000/api/fetchevent`,{
  const response = await axios.post(`https://werlins.de/lwm/api/fetchevent`, {
      _eventId, _addr, _sign},
    {"headers": {
       'Content-Type': 'application/json',
    }
  })
    return response.data;//alert(response.data.message)
  } catch (e) {
    alert(e.response.data.message)
  }
}



export const fetchEvents = async (_eventId,_addr) => {
  try {
  // const response = await axios.post(`http://172.29.49.97:5000/api/fetchevents`,{
  const response = await axios.post(`https://werlins.de/lwm/api/fetchevents`, {
      _eventId,_addr},
    {"headers": {
       'Content-Type': 'application/json',
    }
  })
    return response.data;//alert(response.data.message)
  } catch (e) {
    alert(e.response.data.message)
  }
}


export const addEvent = async (name, timestamp, info, totalTickets,ipfs,
			anzNr, nxtAnzNr, infoFull, ipfsFull, srcString,state) => {
// für hardhat local server zunächst hostname -I => localhost=172.29.49.97   
  try {
//   const response = await axios.post(`http://172.29.49.97:5000/api/addevent`, {
const response = await axios.post(`https://werlins.de/lwm/api/addevent`, {		
      name, timestamp, info, totalTickets,ipfs,
			anzNr, nxtAnzNr, infoFull, ipfsFull, srcString,state
    }, 
    {"headers": {
       'Content-Type': 'application/json', 
    }
   })
    alert(response.data.message)
  } catch (e) {
    alert(e.response.data.message)
  }
}

export const editEvent = async (eventId,name, timestamp, info, totalTickets,ipfs,
			anzNr, nxtAnzNr, infoFull, ipfsFull, srcString,state,addr,sign) => {
// für hardhat local server zunächst hostname -I => localhost=172.29.49.97   
  try {
//   const response = await axios.post(`http://172.29.49.97:5000/api/addevent`, {
const response = await axios.post(`https://werlins.de/lwm/api/editevent`, {		
     eventId,name, timestamp, info, totalTickets,ipfs,
			anzNr, nxtAnzNr, infoFull, ipfsFull, srcString,state,addr,sign
	 
    }, 
    {"headers": {
       'Content-Type': 'application/json', 
    }
   })
    alert(response.data.message)
  } catch (e) {
    alert(e.response.data.message)
  }
}
export const handleBuyTicket = async (eventId, addr) => {
  try {
  // const response = await axios.post(`http://172.29.49.97:5000/api/buytik`,{
  const response = await axios.post(`https://werlins.de/lwm/api/buytik`, {
      eventId,
      addr,
    },
    {"headers": {
       'Content-Type': 'application/json',
    }
  })
    alert(response.data.message)
  } catch (e) {
    alert(e.response.data.message)
  }
}

export const checkTicket = async (eventId, ticketId) => {
  try {
//localhost durch 172.29.49.97 ersetzt????

 // const response = await axios.post(`http://172.29.49.97:5000/api/check`, {
 const response = await axios.post(`https://werlins.de/lwm/api/check`, {
      eventId,
      ticketId,
    },
    {"headers": {
       'Content-Type': 'application/json',
    }
  })

    alert(response.data.message)
  } catch (e) {
    alert(e.response.data.message)
  }
}
//--------------------

export const fetchImg = async (nm) => {
	 try {
 const response = await axios.get(`https://werlins.de/lwm/api/file`, {name:nm},
    {"headers": {
       'Content-Type': 'application/json',
    }
  })

    alert(response.data.message)
  } catch (e) {
    alert(e.response.data.message)
  }
}
	