import React from 'react'
import {
Text,
  Box,
  Center,
  Flex,
  Spacer,
  IconButton,
  useColorMode,
  useColorModeValue,
  Link,
} from '@chakra-ui/react'
import { SunIcon, MoonIcon } from '@chakra-ui/icons';
import ConnectWallet from '../connectWallet/ConnectWallet';

const Navbar = () => {
  const { colorMode, toggleColorMode } = useColorMode()

  const iconColor = useColorModeValue('white', 'white')

  return (
    <Box bg='gray.800' width='100vw'>
	<ConnectWallet />
    </Box>
  )
}

export default Navbar
