import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ChakraProvider, Box, Flex, theme, extendTheme, Center, Wrap, WrapItem } from '@chakra-ui/react'
import GeoEgg	from './geoegg/GeoEgg'
import { EthereumProvider } from './ethereumContext/EthereumContext'
import Navbar from './navbar/Navbar'
//import Footer from './footer/Footer'
//import GeoEgg	from './geoegg/GeoEgg'

function App() {
  const theme=extendTheme({
	colors:{},
	fonts:{},
	fontSizes:{
		sm: "8px",
		md: "12px",
		lg: "16px",
		xl: "20px",
		'2xl': "24px",
		
	},
	breakpoints:{
		sm: "320px",
		md: "768px",
		lg: "960px",
		xl: "1200px",
		'2xl': "1536px",		
	},
  });
  
  return (
    <ChakraProvider theme={theme}>
	<Flex fontSize={['sm', 'md', 'lg', 'xl']}>
      <Flex direction='column'  >
        <BrowserRouter>
          <EthereumProvider>
            <Box flex='1'>
			<Navbar />
              <Routes>
                <Route path='/' element={<GeoEgg />} />
				<Route path='/geoegg' element={<GeoEgg />} />
              </Routes>
            </Box>
            <Box>

            </Box>
          </EthereumProvider>
        </BrowserRouter>
      </Flex>
	</Flex>
    </ChakraProvider>
  )
}

export default App
