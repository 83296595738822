import React, { useState, useEffect, useLayoutEffect, useRef  } from 'react';
import { useEthereumContext } from '../ethereumContext/EthereumContext';
import  { altcha }   from "altcha"
//import  'altcha'; //Altcha  from "react-altcha";
import { Box, Text, Input, Stack, HStack, VStack,Card, CardHeader, Heading, CardBody, StackDivider} from '@chakra-ui/react';
//import {captchaChallenge } from '../../utils/actions';
import { verifyChallenge }  from '../../utils/actions';
import localforage from 'localforage';

function ProtectedForm() {

	const [altchaStrings,setAltchaStrings]=useState(JSON.stringify({
		  "ariaLinkLabel": "Visit Altcha.org",
		  "error": "Die Prüfung war leider erfolglos.",
		  "expired": "Die Prüfung dauert leider zu lang.",
		  "footer": "Protected by <a href=\"https://altcha.org/\" target=\"_blank\" aria-label=\"Visit Altcha.org\">ALTCHA</a>",
		  "label": "Ich spiele mit eigenem Kopf",
		  "verified": "Ok - klick unten auf Start",
		  "verifying": "Ich prüfe ob KI Bot...",
		  "waitAlert": "In Prüfung... etwas Geduld."
		}))
		
	const   { hstatRef, hstat, setHstat, accountRef, account, setAccount, signerRef, signer, setSigner, statInfo, setStatInfo,challengeJson,setChallengeJson }
		= useEthereumContext();
		//const accRef = useRef();
	const challengeurl="https://werlins.de/lwm/api/captchaChallenge";

/*
		const signAccRef = async () => {
			try {
				if (signer&&account) {
					const msg =new TextEncoder().encode("message");
					const sig = await signer.signMessage(msg);
					return sig; 
				}
			} catch (err) {
				console.log("Error signAccRef: ",err);
			}
		}

		const gChallenge = async () => {
		const sig = await signAccRef();	
		console.log("gChallenge sig: ",sig);
		const retobj = await captchaChallenge(accRef.current,sig); 
		console.log("retoj von captchaCallenge: ",retobj);
		const ret=JSON.stringify(retobj);
		if ((retobj.nonce === 'undefined' )||(Number(retobj.nonce)<2)) {
			console.log("retob.nonce undefined: ",typeof retobj.nonce);
			hstatRef.current=false; 
			
			//localStorage.setItem('hstat',JSON.stringify(false));
			setHstat(false);
		}
		else 
		{ 
			console.log("hstat: ",hstat);
			hstatRef.current=true;
			localStorage.setItem('hstat',JSON.stringify(true));
			setHstat(true)
		}	
		console.log("setChallengeJson(ret):",ret);
		setChallengeJson(ret);//.ret);
		}

		useLayoutEffect(() => {
    //challengeUrl = challengeurl.current;
		if (typeof(accRef.current)==='string' && accRef.current.length >0 ){
			gChallenge();
		}
		}, [accRef.current]);//,hstatRef.current]); 
 
 */
 useLayoutEffect(() => {
   localforage.getItem('hstat').then(hs => {	
	if (hs === null) {
		hstatRef.current=false;
		localforage.setItem('hstat',false).then(() => {
			setHstat(false);
		}).catch(error => {
			console.error('Error storing hstat false:', error);
		});
	}else {
		hstatRef.current=hs;
		setHstat(hs);
	}
	console.log("hstatRef.current",hstatRef.current);
  }).catch(error => {
			console.error('Error storing hstat false:', error);
  });
},[]); 

  const vfyUrl = "https://werlins.de/lwm/api/verifyChallenge";
   
  const handleSubmit= (e) => {
	e.preventDefault();
    console.log(e);
    hstatRef.current=true;
	localforage.setItem('hstat',true).then(() => {
		setHstat(true);
		console.log('hstat stored successfully!: ',"true");
	}).catch(error => {
		console.error('Error storing hstat :', error);
	});	
    //localStorage.setItem('hstat',JSON.stringify(true));
  }
/*  
  useLayoutEffect(() => {
   if (typeof(account)==='string' && account.length>0) {
		accRef.current=account;
		let hs = (localStorage.getItem('hstat')!==null)? JSON.parse(localStorage.getItem('hstat')):false;
		console.log("hs: ",hs);
		if (hs !== null){
			hstatRef.current=hs;
			setHstat(hs);
		} 
		else {
			hstatRef.current=false;
			localStorage.setItem('hstat',JSON.stringify(false));
			setHstat(false);
		}
   }
  }, [account]);

  
 const handleChangeAcc = (event) => {
	accRef.current=event.target.value;
	console.log("accRef.current: ",accRef.current);	
	hstatRef.current=false;
    localStorage.setItem('hstat',JSON.stringify(false));
	
  };   
*/
  const challengeUrl = "https://werlins.de/lwm/api/captchaChallenge";
 // typeof(challengeJson)==='string' && challengeJson.length>0 && showCaptcha<->true
  return (
  <div>
  {!hstatRef.current && typeof account === 'string' && account.length >0 &&  <form onSubmit={handleSubmit}>
      {/* ...form fields */}

	{!hstatRef.current && <Text> Prüfung ob Mensch oder KI-Bot </Text> }

      <altcha-widget
        challengejson = {challengeJson}
        verifyurl={vfyUrl}
        workers={8}
        spamfilter
        strings={altchaStrings}
		hidelogo
		hidefooter
      >
      </altcha-widget>

    <button type="submit">Start-></button>
    </form>	
	
}
  </div>
  )
}
export  default ProtectedForm;